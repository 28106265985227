import { addTestToCart, frequentlyBooked } from "./test-booker";

const ts = document.querySelectorAll(".bookNowTest");
const booked = document.querySelectorAll(".frequentlyBookedTests");

Object.entries(booked).forEach(([node, t]) => {
    t.addEventListener("click", frequentlyBooked, false);
});

Object.entries(ts).forEach(([node, t]) => {
    t.addEventListener("click", addTestToCart, false);
});

if (document.querySelector("#allTests")) {
    document
        .querySelector("#allTests")
        .addEventListener("click", function (event) {
            if (
                event.target &&
                event.target.matches(".frequentlyBookedTests")
            ) {
                event.target.addEventListener("click", frequentlyBooked, false);
                event.target.click();
            }
        });
}

if (document.querySelector("#allTests")) {
    document
        .querySelector("#allTests")
        .addEventListener("click", function (event) {
            if (event.target && event.target.matches(".bookNowTest")) {
                event.target.addEventListener("click", addTestToCart, false);
                event.target.click();
            }
        });
}
